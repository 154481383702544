import { useEffect, useMemo ,useState} from 'react';
import Print from "@mui/icons-material/Print";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

import { ActionIcon } from '@mantine/core';

import MaterialReactTable,
{
  MRT_FullScreenToggleButton,
  MRT_ShowHideColumnsButton,
  MRT_ToggleGlobalFilterButton,
} from 'material-react-table';
import {
// Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  // Stack,
  // TextField,
  Typography

} from '@mui/material';


export const ModalProcesoCierre = ({ open, onClose, tableDataBodegaId ,idVista }) => {
  const [title, setTitle] = useState('Cierre bodega');

  const columnsCierreBodega = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'id',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: 'periodo',
        header: 'periodo',
        size: 80,
      },
      {
        accessorKey: 'bodega.descripcion',
        header: 'bodega',
        size: 80,
      },
      {
        accessorKey: 'articulo.descripcion',
        header: 'articulo',
        size: 80,
      },

      {
        accessorKey: 'preciopromedio',
        header: 'precio promedio',
        size: 80,
      },
      {
        accessorKey: 'saldounitario',
        header: 'saldo unitario',
        size: 80,
      },
      {
        accessorKey: 'saldovalor',
        header: 'saldo valor',
        size: 80,
      },

    ],
    [],
  );

  const get_data_to_pdf = () => {
    let dataPdf = [];

    for (let i = 0; i < tableDataBodegaId.length; i++) {

      const fila = [  tableDataBodegaId[i].id,
      tableDataBodegaId[i].periodo,
      tableDataBodegaId[i].bodega.descripcion,
      tableDataBodegaId[i].articulo.descripcion,
      tableDataBodegaId[i].preciopromedio,
      tableDataBodegaId[i].saldounitario,
      tableDataBodegaId[i].saldovalor,

      ];
      dataPdf = [...dataPdf, fila];

    };

    return dataPdf;

  }

  const get_column_to_pdf = () => {
    let columnPdf = [];

    for (let i = 0; i < columnsCierreBodega.length; i++) {

      columnPdf = [...columnPdf, columnsCierreBodega[i].header];

    };

    return columnPdf;
  }

  const downloadPdf = () => {
    const doc = new jsPDF()

    autoTable(doc, { html: '#my-table' })

    doc.text(`${title} ID: ${idVista} `, 15, 10);
    autoTable(doc, {

      head: [get_column_to_pdf()],
      body: get_data_to_pdf(),

    })

    doc.save('table.pdf')
  };


  useEffect(() => {
    console.log("modal  el array ", tableDataBodegaId);


  }, [open]);

  return (
    <>
      <Dialog open={open}   maxWidth="lg" >

        <DialogTitle textAlign="center" >

          <div style={{ display: 'flex' }}>
            <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
              Cierre de bodegas
            </Typography>

          </div>


        </DialogTitle>
        <DialogContent dividers >
          <MaterialReactTable
            displayColumnDefOptions={{
              'mrt-row-actions': {
                muiTableHeadCellProps: {
                  align: 'center',
                },
                size: 120,
              },
            }}

            columns={columnsCierreBodega}

            data={tableDataBodegaId}


            enableTopToolbar={true} //hide top toolbar
            enableBottomToolbar={true} //hide bottom toolbar

            editingMode="modal" //default
            enableColumnOrdering
            //enableEditing
            renderToolbarInternalActions={({ table }) => (
              <>
                <ActionIcon
                  onClick={() => {
    
                    // window.print();
                    downloadPdf();
    
                  }}
                >
                  <Print />
                </ActionIcon>
    

    
                {/* built-in buttons (must pass in table prop for them to work!) */}
                <MRT_ToggleGlobalFilterButton table={table} />
                <MRT_ShowHideColumnsButton table={table} />
                <MRT_FullScreenToggleButton table={table} />
              </>
            )}

          />


        </DialogContent>
        <DialogActions sx={{ p: '1.25rem' }}>
          <Button color="secondary" onClick={onClose}>Ok</Button>

        </DialogActions>
      </Dialog>
    </>
  );
};


export default ModalProcesoCierre;
